<template>
	<form type="POST" v-on:submit.prevent="storeProveedores()" ref="proveedoresForm">
		<h3 class="mb-3 text-primary">
			<font-awesome-icon :icon="faPlus" /> {{title}}
		</h3>
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-lg-9 mb-3">
						<label for="descripcion">Descripción:</label>
						<input type="text" name="descripcion" id="descripcion" class="form-control" placeholder="Ingrese descripción" v-model="form.NOMBRE_PROVEEDOR" maxlength="150" required>
						<span v-if="errors.NOMBRE_PROVEEDOR" class="text-danger">
							{{errors.NOMBRE_PROVEEDOR[0]}}
						</span>
					</div>
					<div class="col-lg-3 mb-3">
						<label for="pais">País:</label>
						<Paises name="pais" id="pais" v-model="form.CODIGO_PAIS" required />
						<span v-if="errors.CODIGO_PAIS" class="text-danger">
							{{errors.CODIGO_PAIS[0]}}
						</span>
					</div>

					<div class="col-lg-8 mb-3">
						<label for="nombre_facturacion">Nombre de facturación:</label>
						<input type="text" name="nombre_facturacion" id="nombre_facturacion" class="form-control" placeholder="Ingrese nombre" v-model="form.NOMBRE_FACTURACION" maxlength="250" required>
						<span v-if="errors.NOMBRE_FACTURACION" class="text-danger">
							{{errors.NOMBRE_FACTURACION[0]}}
						</span>
					</div>
					<div class="col-lg-4 mb-3">
						<label for="nit">NIT:</label>
						<input type="text" name="nit" id="nit" class="form-control" placeholder="Ingrese NIT" v-model="form.NIT" maxlength="50">
						<span v-if="errors.NIT" class="text-danger">
							{{errors.NIT[0]}}
						</span>
					</div>
					<div class="col-lg-3 mb-3">
						<label for="tipo_proveedor">Tipo de proveedor:</label>
						<TipoProveedor name="tipo_proveedor" id="tipo_proveedor" v-model="form.CODIGO_PROVEEDOR_TIPO" required />
						<span v-if="errors.CODIGO_PROVEEDOR_TIPO" class="text-danger">
							{{errors.CODIGO_PROVEEDOR_TIPO[0]}}
						</span>
					</div>
					<div class="col-lg-3 mb-3">
						<label for="telefono">Teléfono:</label>
						<input type="tel" name="telefono" id="telefono" class="form-control" placeholder="Ingrese teléfono" v-model="form.TELEFONO" maxlength="150">
						<span v-if="errors.TELEFONO" class="text-danger">
							{{errors.TELEFONO[0]}}
						</span>
					</div>
					<div class="col-lg-3 mb-3">
						<label for="correo">Correo electrónico:</label>
						<input type="mail" name="correo" id="correo" class="form-control" placeholder="Ingrese correo" v-model="form.CORREO" maxlength="150">
						<span v-if="errors.CORREO" class="text-danger">
							{{errors.CORREO[0]}}
						</span>
					</div>
					<div class="col-lg-3 mb-3">
						<label for="sitio_web">Sitio web:</label>
						<input type="text" name="sitio_web" id="sitio_web" class="form-control" placeholder="Ingrese URL" v-model="form.SITIOWEB" maxlength="150">
						<span v-if="errors.SITIOWEB" class="text-danger">
							{{errors.SITIOWEB[0]}}
						</span>
					</div>
					<div class="col-lg-6 mb-3">
						<label for="notas">Notas:</label>
						<textarea type="text" name="notas" id="notas" class="form-control" placeholder="Ingrese notas" v-model="form.NOTAS" maxlength="500"></textarea>
						<span v-if="errors.NOTAS" class="text-danger">
							{{errors.NOTAS[0]}}
						</span>
					</div>
					<div class="col-lg-6 mb-3">
						<label for="direccion">Dirección:</label>
						<textarea type="text" name="direccion" id="direccion" class="form-control" placeholder="Ingrese dirección" v-model="form.DIRECCION" maxlength="250"></textarea>
						<span v-if="errors.DIRECCION" class="text-danger">
							{{errors.DIRECCION[0]}}
						</span>
					</div>

					<div class="col-lg-12 mb-3">
						<label for="adjunto">Fotos:</label>
						<UploadFile id="adjuntos" tipo="proveedores" v-model="form.adjuntos" :adjuntos="adjuntos" :limitFile="1" accept="image/*" />
						<span v-if="errors.adjuntos" class="text-danger">
							{{errors.adjuntos[0]}}
						</span>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex justify-content-end align-items-center">
					<div class="me-2">
						<router-link :to="{name: 'admin.proveedores.index'}" class="btn btn-link">
							Cancelar
						</router-link>
					</div>
					<div>
						<div class="btn-group">
							<button type="submit" class="btn btn-primary" :disabled="loading || !validateForm">
								<BtnLoading v-if="loadingProveedores" />
								<span v-else>
									<font-awesome-icon :icon="faSave" /> Crear proveedor
								</span>
							</button>
							<button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" :disabled="loading || !validateForm">
								<span class="visually-hidden">Más opciones</span>
							</button>
							<ul class="dropdown-menu dropdown-menu-end">
								<li>
									<a class="dropdown-item" href="#" v-on:click.prevent="storeProveedores(true)">Crear y nuevo</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>
<script type="text/javascript">
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
	import { faSave, faPlus } from '@fortawesome/free-solid-svg-icons'
	import BtnLoading from '@/components/BtnLoading.vue'
	import Paises from '@/components/catalogos/Paises.vue'
	import TipoProveedor from '@/components/catalogos/TipoProveedor.vue'
	import UploadFile from '@/components/UploadFile.vue'
	export default {
		components: {
			FontAwesomeIcon,
			BtnLoading,
			Paises,
			TipoProveedor,
			UploadFile
		},
		data(){
			return {
				faSave,
				faPlus,
				form: {
					NOMBRE_PROVEEDOR: '',
					CODIGO_PAIS: '',
					NOMBRE_FACTURACION: '',
					NIT: '',
					CODIGO_PROVEEDOR_TIPO: '',
					TELEFONO: '',
					CORREO: '',
					SITIOWEB: '',
					NOTAS: '',
					DIRECCION: '',
					adjuntos: []
				},
				adjuntos: [],
				loadingProveedores: false,
				validateForm: false
			}
		},
		methods: {
			storeProveedores(newItems=false){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingProveedores = true
				this.$store.dispatch('storeProveedores', this.form).then((res) => {
					this.$toast.info(`El proveedor ha sido creado`)
					if (newItems) {
						this.form.NOMBRE_PROVEEDOR = ''
						this.form.CODIGO_PAIS = ''
						this.form.NOMBRE_FACTURACION = ''
						this.form.NIT = ''
						this.form.CODIGO_PROVEEDOR_TIPO = ''
						this.form.TELEFONO = ''
						this.form.CORREO = ''
						this.form.SITIOWEB = ''
						this.form.NOTAS = ''
						this.form.DIRECCION = ''
						this.form.adjuntos = []
						this.adjuntos = []
					}else{
						this.$router.push({name: 'admin.proveedores.index'})
					}
					return res
				}).catch(err => {
					if(err.response){
						if(typeof err.response.data === 'string'){
							this.$toast.error(err.response.data)
						}else{
							this.$toast.error('Error al crear registro')
						}
					}
				}).then(() => {
					this.loadingProveedores = false
				})
			},
			checkValidity(){
				if (!this.$refs.proveedoresForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.proveedoresForm.checkValidity()
				}
			},
		},

		computed:{
			title(){
				return this.$route.meta.title ? this.$route.meta.title : ''
			},

			loading() {
				return this.$store.getters.loading
			},

			errors() {
				return this.$store.getters.errors || {}
			}
		},
		watch: {
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		}
	}
</script>